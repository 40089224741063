import Search from "../Search"

function Header() {
	return (
		<header>
			<Search />
		</header>
	)
}

export default Header